import React from 'react'
import initials from '../utils/initials'

export default function Header({ baseObjects, setShowChat }) {
  const { liveStream, ticket, event } = baseObjects
  return (
    <div className="live-chat__header">
      <div
        className="live-chat__header-toggle"
        onClick={() => {
          setShowChat(false)
        }}
      ></div>
      <div className="live-chat__header-title">{liveStream.name}</div>
      {ticket ? (
        <a
          target="_blank"
          href={`${event.website}/ticket/${ticket.id}/${ticket.token}/edit`}
          className="live-chat__header-avatar"
          rel="noreferrer"
        >
          {initials(ticket.name)}
        </a>
      ) : (
        <div></div>
      )}
    </div>
  )
}

Header.displayName = 'Header'
