export default function (name) {
  if (!name) return 'X'
  name = name + ''
  name = name.trim()
  const parts = name.split(' ')
  if (parts.length > 1) {
    return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase()
  } else {
    return parts[0].charAt(0).toUpperCase()
  }
}
