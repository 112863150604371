import React, { useState, useEffect, useRef } from 'react'
import OT from '@opentok/client'

import AudioLevel from './audio-level'
import intlGet from '../utils/intl-get'

const cameraOptions = {
  width: '100%',
  height: '100%',
  showControls: false,
  insertMode: 'append',
  resolution: '1280x720',
  frameRate: 30,
}
const screenOptions = {
  videoSource: 'screen',
  width: '100%',
  height: '100%',
  showControls: false,
  insertMode: 'append',
  resolution: '1280x720',
  frameRate: 30,
}

export default function Publisher(props) {
  const {
    isLive,
    isTesting,
    isConnected,
    publish,
    unpublish,
    screenShareOn,
    setScreenShareOn,
    cameraOn,
    micOn,
    audioDeviceId,
    videoDeviceId,
    cycleVideoDevice,
    setCycleVideoDevice,
    setVideoDeviceId,
    errorHandler,
    isPublishingCamera,
    isPublishingScreen,
    setIsPublishingCamera,
    setIsPublishingScreen,
    setPublisherIsLoading,
  } = props

  const screenRef = useRef(null)
  const cameraRef = useRef(null)
  const [audioLevel, setAudioLevel] = useState(null)
  const [cameraPublisher, setCameraPublisher] = useState(null)
  const [screenPublisher, setScreenPublisher] = useState(null)

  useEffect(() => {
    setPublisherIsLoading(true)
    if (audioDeviceId) cameraOptions.audioSource = audioDeviceId
    if (videoDeviceId) cameraOptions.videoSource = videoDeviceId

    const publisher = OT.initPublisher(cameraRef.current, cameraOptions, (error) => {
      if (error) return errorHandler(error)
      setPublisherIsLoading(false)
      setCameraPublisher(publisher)
      publisher.on('audioLevelUpdated', ({ audioLevel }) => {
        setAudioLevel(audioLevel)
      })
    })
    return () => {
      if (isPublishingCamera) {
        if (isConnected) unpublish(publisher)
        setIsPublishingCamera(false)
      }
      publisher.off()
      publisher.destroy()
      setCameraPublisher(null)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const destroyScreenPublisher = (publisher) => {
      if (!publisher) return
      if (isPublishingScreen) {
        if (isConnected) unpublish(publisher)
        setIsPublishingScreen(false)
      }
      publisher.off()
      publisher.destroy()
      setScreenPublisher(null)
    }
    if (screenShareOn && !isPublishingScreen) {
      const publisher = OT.initPublisher(screenRef.current, screenOptions, (error) => {
        if (error) {
          setScreenShareOn(false)
          return errorHandler(error)
        } else {
          setScreenPublisher(publisher)
          publisher.on('mediaStopped', () => {
            destroyScreenPublisher(publisher)
          })
          publisher.on('streamDestroyed', () => {
            destroyScreenPublisher(publisher)
          })
        }
      })
      return () => {
        destroyScreenPublisher(publisher)
      }
    } else if (!screenShareOn && screenPublisher) {
      destroyScreenPublisher(screenPublisher)
    }
  }, [screenShareOn]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!cameraPublisher) return
    cameraPublisher.publishVideo(cameraOn)
  }, [cameraOn, cameraPublisher])

  useEffect(() => {
    if (!cameraPublisher) return
    cameraPublisher.publishAudio(micOn)
  }, [micOn, cameraPublisher])

  useEffect(() => {
    if (!cameraPublisher && !isConnected) return
    if (isLive && !isPublishingCamera) {
      publish(cameraPublisher).then(() => {
        setIsPublishingCamera(true)
      })
    } else if (!isLive && isPublishingCamera) {
      unpublish(cameraPublisher)
      setIsPublishingCamera(false)
    }
  }, [isConnected, cameraPublisher, isLive, isPublishingCamera, setIsPublishingCamera, publish, unpublish])

  useEffect(() => {
    if (!screenPublisher && !isConnected) return
    if (isLive && screenShareOn && !isPublishingScreen) {
      publish(screenPublisher).then(() => {
        setIsPublishingScreen(true)
      })
    } else if (!isLive && isPublishingScreen) {
      unpublish(screenPublisher)
      setIsPublishingScreen(false)
    }
  }, [
    isConnected,
    screenPublisher,
    isLive,
    screenShareOn,
    isPublishingScreen,
    setIsPublishingScreen,
    publish,
    unpublish,
  ])

  useEffect(() => {
    if (cameraPublisher && cycleVideoDevice) {
      cameraPublisher.cycleVideo().then(({ deviceId }) => {
        setVideoDeviceId(deviceId)
        setCycleVideoDevice(false)
      })
    }
  }, [cameraPublisher, cycleVideoDevice, setVideoDeviceId, setCycleVideoDevice])

  return (
    <div
      className={`live-video__presenter live-video__presenter--publisher ${
        isTesting ? 'live-video__presenter--publisher-test' : ''
      } ${screenShareOn ? ' live-video__presenter--share-screen' : ''}`}
    >
      {isTesting && <div className="live-video__presenter--publisher-test-info">{intlGet('liveStream.testTech')}</div>}
      <div ref={screenRef} className="live-video__screen"></div>
      <div ref={cameraRef} className="live-video__camera"></div>
      <div className="live-video__name">You</div>
      <AudioLevel muted={!micOn} audioLevel={audioLevel} />
    </div>
  )
}

Publisher.displayName = 'Publisher'
