import React, { useState, useRef, useEffect } from 'react'
import Video from './video'
import Chat from './chat'
import Errors from './errors'
import usePublishRequest from './hooks/use-publish-request'
import useSession from './hooks/use-session'
import isMobile from '../../is-mobile'
import useFullscreen from './hooks/use-fullscreen'
import { windowEventsService, EVENT_TYPE } from '../shared/utils/window-events'

export const BLOCK_MODE = {
  block: 'block',
  theater: 'theater',
  fullscreen: 'fullscreen',
}

export const STATUS = {
  completed: 'completed',
  deleted: 'deleted',
  created: 'created',
}

export default function LiveStream({ apiKey, ticket: ticketFromProps, liveStream, event, csrf, block, image }) {
  const [errors, setErrors] = useState([])
  const errorHandler = (error) => {
    if (!error) return
    setErrors([error, ...errors.slice(0, 2)])
  }

  const [ticket, setTicket] = useState(ticketFromProps)

  const baseObjects = { ticket, liveStream, event, csrf }
  const needTicketToWatch = !liveStream.token
  const [messages, setMessages] = useState([])
  const [micOn, setMicOn] = useState(true)
  const [backgroundImage, setBackgroundImage] = useState(image)
  const [chatDisabled, setChatDisabled] = useState(liveStream.chatDisabled || needTicketToWatch)
  const [chatOpen, setChatOpen] = useState(!chatDisabled && liveStream.chatOpen)
  const [showChat, setShowChat] = useState(!chatDisabled)
  const [showViewers, setShowViewers] = useState(liveStream.showViewers || false)
  const [backgroundMessage, setBackgroundMessage] = useState(liveStream.message)
  const [allowJoin, setAllowJoin] = useState(liveStream.allowJoin)
  const [isTesting, setIsTesting] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const { publishRequest, loadPublishRequest, createPublishRequest, abortPublishRequest, askToJoin, abortJoin } =
    usePublishRequest({
      baseObjects,
      errorHandler,
    })
  const sessionObjects = useSession({
    apiKey,
    liveStream,
    block,
    publishRequest,
    loadPublishRequest,
    setMessages,
    setMicOn,
    setShowChat,
    setChatDisabled,
    setAllowJoin,
    setBackgroundImage,
    setBackgroundMessage,
    setChatOpen,
    setShowViewers,
    setIsLive,
    setIsTesting,
    errorHandler: errorHandler.bind(this),
  })
  const { liveStreamStatus } = sessionObjects
  const liveStreamElementRef = useRef(null)
  const [blockMode, setBlockMode] = useState(isMobile ? BLOCK_MODE.theater : block.mode)
  const blockModeClassName = `live--${blockMode}-mode`

  useFullscreen({ liveStreamElementRef, blockMode, setBlockMode })

  useEffect(() => {
    if (liveStreamStatus === STATUS.completed) {
      setChatOpen(false)
    }
  }, [liveStreamStatus])

  useEffect(() => {
    const updateTicketInState = (e) => {
      if (e?.detail?.ticket) {
        setTicket(e.detail.ticket)
      }
    }

    windowEventsService.subscribe(EVENT_TYPE.ticketChanged, updateTicketInState)
    return () => {
      windowEventsService.unsubscribe(EVENT_TYPE.ticketChanged, updateTicketInState)
    }
  }, [])

  return (
    <div ref={liveStreamElementRef} className={`live ${blockModeClassName}`}>
      <div className="live__content">
        <Errors errors={errors} setErrors={setErrors} />
        <Video
          baseObjects={baseObjects}
          sessionObjects={sessionObjects}
          backgroundImage={backgroundImage}
          backgroundMessage={backgroundMessage}
          micOn={micOn}
          setMicOn={setMicOn}
          blockMode={blockMode}
          setBlockMode={setBlockMode}
          showChat={showChat}
          setShowChat={setShowChat}
          showViewers={showViewers}
          chatDisabled={chatDisabled}
          allowJoin={allowJoin}
          publishRequest={publishRequest}
          needTicketToWatch={needTicketToWatch}
          createPublishRequest={createPublishRequest}
          abortPublishRequest={abortPublishRequest}
          askToJoin={askToJoin}
          abortJoin={abortJoin}
          isTesting={isTesting}
          setIsTesting={setIsTesting}
          isLive={isLive}
          setIsLive={setIsLive}
          errorHandler={errorHandler.bind(this)}
        />
        <Chat
          baseObjects={baseObjects}
          showChat={showChat}
          chatOpen={chatOpen}
          setShowChat={setShowChat}
          messages={messages}
          setMessages={setMessages}
          errorHandler={errorHandler.bind(this)}
        />
      </div>
    </div>
  )
}
