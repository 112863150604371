import React from 'react'
import intlGet from '../utils/intl-get'
import Background from './background'

export default function Message({ message, image, needTicketToWatch, event }) {
  return (
    <div className="live-video__message-container">
      {needTicketToWatch && (
        <div className="live-video__message">
          <div className="live-video__message-text">{intlGet('liveStream.signUp')}</div>
          <a className="live-video__message-btn" href={event.signupUrl}>
            {event.callToAction}
          </a>
        </div>
      )}
      {!needTicketToWatch && message && <div className="live-video__message">{message}</div>}
      <div className="live-video__background">
        <Background image={image} />
      </div>
    </div>
  )
}
Message.displayName = 'Message'
