import React, { useState, useEffect, useRef } from 'react'

import intlGet from '../utils/intl-get'
import uuidv4 from '../../../utils/uuidv4'
import textareaAutoExpand from '../../../textarea-auto-expand'

export default function Form({ liveStream, ticket, event, csrf, chatOpen, scrollToBottom, errorHandler }) {
  const messageRef = useRef(null)
  const buttonRef = useRef(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    textareaAutoExpand(messageRef.current)
  }, [])

  const updateMessage = (e) => {
    const { value } = e.target
    setMessage(value)
    buttonRef.current.disabled = value.length === 0
  }
  const sendMessage = async () => {
    if (buttonRef.current.disabled) return
    buttonRef.current.disabled = true
    messageRef.current.disabled = true
    try {
      await fetch(`${event.website}/common/api/live-stream-messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrf,
        },
        body: JSON.stringify({
          liveStreamMessage: {
            ticketId: ticket.id,
            ticketToken: ticket.token,
            liveStreamId: liveStream.id,
            clientId: uuidv4(),
            body: message,
          },
        }),
      })
    } catch (e) {
      errorHandler(e)
    }
    messageRef.current.disabled = false
    scrollToBottom(true)
    setMessage('')
    textareaAutoExpand.update(messageRef)
  }
  const checkSendMessage = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
      sendMessage()
    }
  }

  const placeholder = chatOpen ? `Write as ${ticket.name}...` : 'Chat is not open.'

  if (ticket && ticket.name) {
    return (
      <div className="live-chat__form-container">
        <div className="live-chat__form">
          <textarea
            ref={messageRef}
            value={message}
            className="live-chat__form-message auto-expand"
            onChange={updateMessage}
            onKeyDown={checkSendMessage}
            rows="1"
            disabled={chatOpen ? false : true}
            data-min-rows="1"
            placeholder={placeholder}
          />
          {chatOpen && (
            <button
              ref={buttonRef}
              type="button"
              onClick={() => sendMessage()}
              className="live-chat__form-button"
              disabled
            >
              <svg
                onClick={() => sendMessage()}
                xmlns="https://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 512 512"
              >
                <title>ionicons-v5-q</title>
                <path d="M476.59,227.05l-.16-.07L49.35,49.84A23.56,23.56,0,0,0,27.14,52,24.65,24.65,0,0,0,16,72.59V185.88a24,24,0,0,0,19.52,23.57l232.93,43.07a4,4,0,0,1,0,7.86L35.53,303.45A24,24,0,0,0,16,327V440.31A23.57,23.57,0,0,0,26.59,460a23.94,23.94,0,0,0,13.22,4,24.55,24.55,0,0,0,9.52-1.93L476.4,285.94l.19-.09a32,32,0,0,0,0-58.8Z" />
              </svg>
            </button>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="live-chat__form-container">
        <div className="live-chat__attendee-information">
          <div className="live-chat__attendee-information-text">{intlGet('liveStream.ticketNeedName')}</div>
          <a href={`${event.website}/ticket/${ticket.id}/${ticket.token}/edit`} className="live-chat__sign-up-button">
            {intlGet('liveStream.addName')}
          </a>
        </div>
      </div>
    )
  }
}
