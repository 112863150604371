import React from 'react'
import MenuItem from './menu-item'
import { BLOCK_MODE } from '../live-stream'
import { useState } from 'react'

export default function Menu(props) {
  const [loading, setIsLoading] = useState({})

  const { publishRequest, ticket, isLive, isTesting } = props
  const { createPublishRequest, abortPublishRequest, askToJoin, abortJoin } = props.actions
  const {
    micOn,
    screenShareOn,
    cameraOn,
    mutedLocal,
    showSettings,
    blockMode,
    showChat,
    chatDisabled,
    allowJoin,
    publisherIsLoading,
    maxPresenters,
    showViewers,
    numberOfViewers,
  } = props.values
  const {
    setMicOn,
    setScreenShareOn,
    setCameraOn,
    setMutedLocal,
    setShowSettings,
    setIsTesting,
    setIsLive,
    setBlockMode,
    setShowChat,
  } = props.setters

  const items = {
    maxPresenters: <MenuItem key="maxPresenters" type="button" copy="maxPresenters" />,
    abortPublishRequest: (
      <MenuItem
        key="abortPublishRequest"
        type="button"
        copy="abort"
        copyExtra="waitingForApproval"
        onClick={async () => {
          setIsLoading({ ...loading, abortPublishRequest: true })
          await abortPublishRequest()
          setIsLoading({ ...loading, abortPublishRequest: false })
        }}
        isLoading={loading.abortPublishRequest}
      />
    ),
    abortGoingLive: (
      <MenuItem
        key="abortGoingLive"
        type="button"
        onClick={async () => {
          setIsLoading({ ...loading, abortJoin: true })
          await abortJoin()
          setIsLoading({ ...loading, abortJoin: false })
        }}
        copy="abort"
        copyExtra="waitingForApproval"
        isLoading={loading.abortJoin}
      />
    ),
    isTesting: <MenuItem key="isTesting" type="button" onClick={() => setIsTesting(true)} copy="lobby" />,
    leaveLobby: <MenuItem key="leaveLobby" type="button" onClick={() => setIsTesting(false)} copy="close" />,
    join: (
      <MenuItem
        key="join"
        type="button"
        onClick={async () => {
          setIsLoading({ ...loading, requestJoin: true })
          await askToJoin()
          setIsLoading({ ...loading, requestJoin: false })
        }}
        copy="join"
        isLoading={loading.requestJoin}
      />
    ),
    leave: <MenuItem key="leave" type="button" onClick={() => setIsLive(false)} copy="leave" />,
    mic: (
      <MenuItem
        key="mic"
        type="toggle"
        dimIf={false}
        value={micOn}
        onClick={setMicOn}
        copy={['micDisable', 'mic']}
        icons={['mic', 'micDisabled']}
      />
    ),
    screenShare: (
      <MenuItem
        key="screenShare"
        type="toggle"
        dimIf={false}
        value={screenShareOn}
        onClick={setScreenShareOn}
        copy={['screenshareDisable', 'screenshare']}
        icons={['screenshare', 'screenshareDisabled']}
      />
    ),
    video: (
      <MenuItem
        key="video"
        type="toggle"
        dimIf={false}
        value={cameraOn}
        onClick={setCameraOn}
        copy={['videoDisable', 'video']}
        icons={['video', 'videoDisabled']}
      />
    ),
    mutedLocal: (
      <MenuItem
        key="mutedLocal"
        type="toggle"
        dimIf={true}
        value={mutedLocal}
        onClick={setMutedLocal}
        copy={['unmute', 'mute']}
        icons={['muted', 'unmuted']}
      />
    ),
    theaterMode: (
      <MenuItem
        key="theaterMode"
        type="activeHide"
        icon="theaterMode"
        copy="theaterMode"
        value={BLOCK_MODE.theater}
        currentValue={blockMode}
        onClick={() => setBlockMode(BLOCK_MODE.theater)}
      />
    ),
    blockMode: (
      <MenuItem
        key="blockMode"
        type="activeHide"
        icon="blockMode"
        copy="blockMode"
        value={BLOCK_MODE.block}
        currentValue={blockMode}
        onClick={() => setBlockMode(BLOCK_MODE.block)}
      />
    ),
    fullscreen: (
      <MenuItem
        key="fullscreen"
        type="activeHide"
        icon="fullscreen"
        copy="fullscreen"
        value={BLOCK_MODE.fullscreen}
        currentValue={blockMode}
        onClick={() => setBlockMode(BLOCK_MODE.fullscreen)}
      />
    ),
    settings: (
      <MenuItem
        key="settings"
        type="toggle"
        value={showSettings}
        onClick={setShowSettings}
        copy={['settings', 'settings']}
        icons={['settings', 'settings']}
      />
    ),
    requestJoin: (
      <MenuItem
        key="requestJoin"
        type="activeHide"
        icon="requestJoin"
        copy="requestJoin"
        value={true}
        currentValue={!!publishRequest}
        onClick={async () => {
          setIsLoading({ ...loading, createPublishRequest: true })
          await createPublishRequest()
          setIsLoading({ ...loading, createPublishRequest: false })
        }}
        isLoading={loading.createPublishRequest}
      />
    ),
    chat: (
      <MenuItem
        key="chat"
        type="activeHide"
        icon="chat"
        copy="showChat"
        value={true}
        currentValue={showChat}
        onClick={() => setShowChat(true)}
      />
    ),
    viewers: <MenuItem key="viewers" type="viewers" icon="viewers" value={numberOfViewers} />,
  }

  let icons = []

  if (publishRequest && !publisherIsLoading) {
    if (publishRequest.isApproved) {
      if (isLive) {
        icons = [...icons, items.leave, items.screenShare, items.mic, items.video, items.settings]
      } else if (isTesting) {
        if (maxPresenters) icons = [...icons, items.maxPresenters]
        icons = [...icons, items.join, items.leaveLobby, items.screenShare, items.mic, items.video, items.settings]
      } else {
        icons = [...icons, items.isTesting]
      }
    } else if (publishRequest.isPending) {
      icons = [...icons, items.abortPublishRequest]
    } else if (publishRequest.isPendingJoin) {
      icons = [...icons, items.abortGoingLive, items.screenShare, items.mic, items.video, items.settings]
    }
  }

  icons = [...icons, items.mutedLocal, items.blockMode, items.theaterMode, items.fullscreen]
  if (!publishRequest && allowJoin && ticket) icons = [...icons, items.requestJoin]
  if (!chatDisabled && !showChat) icons = [...icons, items.chat]

  if (showViewers) {
    icons = [...icons, items.viewers]
  }
  return (
    <div className={`live-video__menu-container ${publishRequest && 'live__video--has-access'}`}>
      <div className="live-video__menu">{icons}</div>
    </div>
  )
}

Menu.displayName = 'Video menu'
