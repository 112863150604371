import { useState, useEffect } from 'react'

const STATUS = {
  approved: 'approved',
  pending: 'pending',
  pendingJoin: 'pending-join',
  declined: 'declined',
}

export default function ({ baseObjects, errorHandler }) {
  const [publishRequest, setPublishRequestInfo] = useState(null)
  const { liveStream, ticket, event, csrf } = baseObjects

  const loadPublishRequest = async ({ token } = {}) => {
    if (!ticket) return setPublishRequestInfo(null)

    async function fetchData() {
      const query = new URLSearchParams()
      query.set('csrf', csrf)
      query.set('ticketId', ticket.id)
      query.set('ticketToken', ticket.token)
      query.set('liveStreamId', liveStream.id)

      const response = await fetch('/common/api/live-stream-publish-request?' + query)
      const json = await response.json()
      const publishRequest = json.liveStreamPublishRequest
      if (!publishRequest) return setPublishRequestInfo(null)
      const { status } = publishRequest

      setPublishRequestInfo({
        isPendingJoin: status === STATUS.pendingJoin,
        isApproved: status === STATUS.approved,
        isPending: status === STATUS.pending,
        token,
        status,
      })
    }
    fetchData()
  }

  const abortPublishRequest = async () => {
    if (!ticket) return
    try {
      await fetch(`${event.website}/common/api/live-stream-publish-requests`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrf,
        },
        body: JSON.stringify({
          liveStreamPublishRequest: {
            ticketId: ticket.id,
            ticketToken: ticket.token,
            liveStreamId: liveStream.id,
            status: STATUS.declined,
          },
        }),
      })
      setPublishRequestInfo(null)
    } catch (e) {
      errorHandler(e)
    }
  }
  const createPublishRequest = async () => {
    if (!ticket) return
    try {
      const response = await fetch(`${event.website}/common/api/live-stream-publish-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrf,
        },
        body: JSON.stringify({
          liveStreamPublishRequest: {
            ticketId: ticket.id,
            ticketToken: ticket.token,
            liveStreamId: liveStream.id,
          },
        }),
      })
      const { liveStreamPublishRequest } = await response.json()
      const { status, providerToken } = liveStreamPublishRequest
      setPublishRequestInfo({
        isApproved: status === STATUS.approved,
        isPending: status === STATUS.pending,
        isPendingJoin: status === STATUS.pendingJoin,
        token: providerToken,
        status,
      })
    } catch (e) {
      errorHandler(e)
    }
  }

  const updatePublishRequest = async (data) => {
    if (!ticket) return
    try {
      const response = await fetch(`${event.website}/common/api/live-stream-publish-requests`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'CSRF-Token': csrf,
        },
        body: JSON.stringify({
          liveStreamPublishRequest: {
            ticketId: ticket.id,
            ticketToken: ticket.token,
            liveStreamId: liveStream.id,
            status: data.status,
          },
        }),
      })

      const { liveStreamPublishRequest } = await response.json()
      const { status } = liveStreamPublishRequest

      setPublishRequestInfo({
        isApproved: status === STATUS.approved,
        isPending: status === STATUS.pending,
        isPendingJoin: status === STATUS.pendingJoin,
        status,
      })
    } catch (e) {
      errorHandler(e)
    }
  }

  const askToJoin = () => {
    return updatePublishRequest({ status: STATUS.pendingJoin })
  }

  const abortJoin = async () => {
    return updatePublishRequest({ status: STATUS.approved })
  }

  useEffect(() => {
    loadPublishRequest()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { publishRequest, loadPublishRequest, createPublishRequest, abortPublishRequest, askToJoin, abortJoin }
}
