import { useState, useEffect } from 'react'

export default (url, { query, success } = {}) => {
  const [res, setRes] = useState(null)

  if (query) {
    const searchParams = new URLSearchParams()
    for (let key in query) {
      searchParams.set(key, query[key])
    }
    url = url + '?' + searchParams
  }
  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    async function fetchData() {
      const options = {}
      const response = await fetch(url, options)
      const json = await response.json()
      setRes(json)
      if (success) success(json)
    }
    fetchData()
  }, [success, url])

  return res
}
