import OT from '@opentok/client'
import React from 'react'
import intlGet from '../utils/intl-get'

export default function AudioActivationOverlay({ setNeedAudioActivation, errorHandler }) {
  const unblockAudio = async () => {
    try {
      await OT.unblockAudio()
    } catch (e) {
      errorHandler({ message: 'Audio activation error', error: e })
    }
    setNeedAudioActivation(false)
  }

  return (
    <div className="live-video__audio-activation-overlay">
      <div className="live-video__audio-activation-overlay-btn" onClick={unblockAudio}>
        {intlGet('liveStream.show')}
      </div>
    </div>
  )
}
AudioActivationOverlay.displayName = 'Audio Activation Overlay'
