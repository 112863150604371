import React, { useEffect, useRef } from 'react'
import iconsFiles from '../../live/utils/icons'
//import BackgroundConfetti from './background-confetti'

import dataImageLoad from '../../../utils/data-image-load'

export default function Background({ image }) {
  const imageRef = useRef(null)
  useEffect(() => {
    if (imageRef && imageRef.current) {
      dataImageLoad(imageRef.current)
    }
  }, [image])
  if (image) {
    return (
      <div className="live-video__background-image-container">
        <div
          ref={imageRef}
          className="live-video__background-image"
          data-image={image.url500}
          data-image-1000={image.url1000}
          data-image-2000={image.url2000}
        ></div>
      </div>
    )
  } else {
    return (
      <div className="live-video__background-logo-container">
        <div
          className="live-video__background-logo"
          dangerouslySetInnerHTML={{ __html: iconsFiles.confettiLive }}
        ></div>
      </div>
    )
  }
}
