import dayjs from 'dayjs'
import React, { useRef, useEffect } from 'react'
import initials from '../utils/initials'

export default function Item({ from, body, isAdmin, deletedAt, createdAt }) {
  const time = dayjs(createdAt).toDate().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  const slug = (from || '').toLowerCase().replace(/[^a-zA-Z0-9]+/g, '')

  if (deletedAt) from = '(deleted)'

  const itemRef = useRef(null)
  useEffect(() => {
    if (isAdmin) itemRef.current.classList.add('live-chat-item--admin')
    if (deletedAt) itemRef.current.classList.add('live-chat-item--deleted')
  }, [deletedAt, isAdmin])

  return (
    <div ref={itemRef} className="live-chat-item">
      <div className="live-chat-item__avatar">{initials(slug)}</div>
      <div className="live-chat-item__content">
        <div className="live-chat-item__meta">
          <div className="live-chat-item__name">{from}</div>
          <div className="live-chat-item__time">{time}</div>
        </div>
        <div className="live-chat-item__message">{body}</div>
      </div>
    </div>
  )
}
