import confettiLive from '../icons/confetti-live.svg'
import muted from '../icons/muted.svg'
import theaterMode from '../icons/theater-mode.svg'
import unmuted from '../icons/unmuted.svg'
import blockMode from '../icons/block-mode.svg'
import fullscreen from '../icons/fullscreen.svg'
import chat from '../icons/chat.svg'
import requestJoin from '../icons/hand.svg'
import mic from '../icons/mic.svg'
import micDisabled from '../icons/mic-disabled.svg'
import screenshare from '../icons/screenshare.svg'
import screenshareDisabled from '../icons/screenshare-disabled.svg'
import video from '../icons/video.svg'
import videoDisabled from '../icons/video-disabled.svg'
import settings from '../icons/settings.svg'
import viewers from '../icons/viewers.svg'

export default {
  confettiLive,
  muted,
  theaterMode,
  unmuted,
  blockMode,
  fullscreen,
  chat,
  requestJoin,
  mic,
  micDisabled,
  screenshare,
  screenshareDisabled,
  video,
  videoDisabled,
  settings,
  viewers,
}
