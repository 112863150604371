import { getParameterByName } from '../utils'

export default function (elem) {
  let url = elem.dataset.image
  const size = Math.max(elem.offsetWidth, elem.offsetHeight)

  if (getParameterByName('sitePreview') === 'true' && elem.dataset.imagePreview) {
    url = elem.dataset.imagePreview
  } else if (size > 1000 && elem.dataset['image-3000']) {
    url = elem.dataset['image-3000']
  } else if (size > 500 && elem.dataset['image-2000']) {
    url = elem.dataset['image-2000']
  } else if (size > 250 && elem.dataset['image-1000']) {
    url = elem.dataset['image-1000']
  } else if (url === undefined || url.length <= 0) {
    return
  }
  elem.classList.add('image-loading')

  const img = document.createElement('img')
  const loaded = function () {
    img.style.position = 'absolute'
    img.style.left = '-100000px'
    document.body.appendChild(img)
    elem.style.backgroundImage = 'url(' + url + ')'
    elem.classList.remove('image-loading')
    elem.classList.add('image-ready')
    elem.dataset.imageWidth = img.offsetWidth
    elem.dataset.imageHeight = img.offsetHeight
    img.remove()
  }

  img.src = url
  img.onload = loaded
  setTimeout(loaded, 3000)
}
