import '../regenerator-runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import LiveStream from './live-stream'

const liveContainers = document.querySelectorAll('.live-react-container')
for (let i = 0; i < liveContainers.length; ++i) {
  const container = liveContainers[i]
  container.querySelector('.live-upgrade').remove()

  const liveStream = {
    id: container.dataset.streamId,
    name: container.dataset.streamName,
    token: container.dataset.streamToken,
    status: container.dataset.streamStatus,
    providerId: container.dataset.streamProviderId,
    chatDisabled: container.dataset.streamChatDisabled === 'true',
    chatOpen: container.dataset.streamChatOpen === 'true',
    allowJoin: container.dataset.streamAllowJoin === 'true',
    showViewers: container.dataset.streamShowViewers === 'true',
    message: container.dataset.streamMessage,
  }
  let image
  if (container.dataset.streamImage500) {
    image = {
      url500: container.dataset.streamImage500,
      url1000: container.dataset.streamImage1000,
      url2000: container.dataset.streamImage2000,
    }
  }

  const event = {
    website: container.dataset.eventWebsite,
    startDate: container.dataset.eventStartDate,
    signupUrl: container.dataset.eventWebsite + container.dataset.eventSignupUrl,
    isSignupOpen: container.dataset.eventIsSignupOpen,
    callToAction: container.dataset.eventCallToAction,
  }

  const csrf = container.dataset.csrf

  let ticket = null
  if (container.dataset.ticketId) {
    ticket = {
      id: container.dataset.ticketId,
      token: container.dataset.ticketToken,
      name: container.dataset.ticketName,
    }
  }
  const apiKey = window.Confetti.opentokPublicKey
  const block = {
    id: container.dataset.blockId,
    mode: container.dataset.blockMode,
  }

  ReactDOM.render(
    <LiveStream
      apiKey={apiKey}
      liveStream={liveStream}
      ticket={ticket}
      event={event}
      csrf={csrf}
      block={block}
      image={image}
    />,
    container
  )
}
