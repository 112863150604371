import React from 'react'
import iconsFiles from '../utils/icons'
import intlGet from '../utils/intl-get'

export default function MenuItem({
  isLoading,
  type,
  copy,
  copyExtra,
  value,
  currentValue,
  icons,
  icon,
  onClick,
  dimIf,
}) {
  if (type === 'button') {
    return (
      <div
        onClick={onClick}
        className={`live-video__menu-button-container ${isLoading ? 'live-video__menu-button-container--loading' : ''}`}
      >
        {copyExtra && <div className="live-video__menu-button-text">{intlGet(`liveStream.${copyExtra}`)}</div>}
        <div
          className={`live-video__menu-button live-video__menu-button--${copy} ${
            isLoading ? 'live-video__menu-button--loading' : ''
          }`}
        >
          {isLoading ? 'Loading' : intlGet(`liveStream.${copy}`)}
        </div>
      </div>
    )
  }
  if (type === 'toggle') {
    let icon
    if (value) {
      icon = iconsFiles[icons[0]]
      copy = copy[0]
    } else {
      icon = iconsFiles[icons[1]]
      copy = copy[1]
    }

    let dim = false
    if (dimIf !== undefined) {
      dim = dimIf === value
    }

    return (
      <div
        data-copy={intlGet(`liveStream.${copy}`)}
        onClick={() => onClick(!value)}
        className={`live-video__menu-icon ${dim ? 'live-video__menu-icon--off' : ''}`}
        dangerouslySetInnerHTML={{ __html: icon }}
      ></div>
    )
  }
  if (type === 'activeHide') {
    if (value === currentValue) return null
    return (
      <div
        onClick={onClick}
        data-copy={intlGet(`liveStream.${copy}`)}
        className={`live-video__menu-icon ${
          icon === 'requestJoin' && isLoading ? 'live-video__menu-icon--loading' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: iconsFiles[icon] }}
      ></div>
    )
  }
  if (type === 'viewers') {
    return (
      <div className="live-vide__menu-viewers">
        <div
          className="live-video__menu-icon"
          data-copy="Viewers"
          dangerouslySetInnerHTML={{ __html: iconsFiles[icon] }}
        ></div>
        <div className="live-vide__menu-viewers-text">{value}</div>
      </div>
    )
  }

  return null
}

MenuItem.displayName = 'Menu item'
