import React from 'react'
import intlGet from '../utils/intl-get'

export default function BodyNeedSignup({ event }) {
  return (
    <div className="live-chat__sign-up">
      <div className="live-chat__sign-up-text">{intlGet('liveStream.chatNeedSignup')}</div>
      <a href={event.signupUrl} className="live-chat__sign-up-button">
        {event.callToAction}
      </a>
    </div>
  )
}

BodyNeedSignup.displayName = 'BodyNeedSignup'
