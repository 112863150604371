import React from 'react'
import uuidv4 from '../../utils/uuidv4'

export default function Errors({ errors, setErrors }) {
  const removeError = (error) => {
    setErrors(errors.filter((e) => e !== error))
  }

  const errorElements = errors.map((error) => {
    let message, description
    if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
      message = 'You need to allow access to your camera and microphone'
      description = 'Allowing access differs depending on your device and browser'
    } else if (error.name === 'OT_PERMISSION_DENIED') {
      message = 'You could not join the stream, please reload page'
      description = `${error.message} (${error.code} ${error.name})`
    } else if (error.message && error.description) {
      message = error.message
      description = error.description
    } else if (error.message && error.error) {
      message = error.message
      description = `${error.error.message} (${error.error.code} ${error.error.name})`
    } else {
      message = 'An error occured, you may need to reload the page'
      description = `${error.message} (${error.code} ${error.name})`
    }

    return (
      <div key={uuidv4()} className="live-errors__item">
        <div className="live-errors__item-close" onClick={() => removeError(error)}>
          +
        </div>
        <div className="live-errors__item-message">{message}</div>
        <div className="live-errors__item-description">{description}</div>
      </div>
    )
  })
  return <div className="live-errors">{errorElements}</div>
}
