import React, { useEffect, useState } from 'react'
import OT from '@opentok/client'

export default function Settings({
  setShowSettings,
  audioDeviceId,
  setAudioDeviceId,
  setCycleVideoDevice,
  errorHandler,
}) {
  const [audioDeviceOptions, setAudioDeviceOptions] = useState([])

  useEffect(() => {
    OT.getDevices((error, devices) => {
      if (error) return errorHandler(error)

      devices = devices.filter((device) => device.kind === 'audioInput')
      setAudioDeviceOptions(
        devices.map(({ deviceId, label }) => {
          return (
            <option key={deviceId} value={deviceId}>
              {label}
            </option>
          )
        })
      )
    })
  }, [errorHandler])

  return (
    <div className="live-settings">
      <div className="live-settings__close" onClick={() => setShowSettings(false)}>
        +
      </div>
      <label className="live-settings__label">Video device</label>
      <button className="live-settings__cycle-video" onClick={() => setCycleVideoDevice(true)}>
        Change video device
      </button>
      <label className="live-settings__label">Audio device</label>
      <div className="live-settings__audio-devices-container">
        <select
          className="live-settings__audio-devices"
          value={audioDeviceId}
          onChange={(e) => setAudioDeviceId(e.target.value)}
        >
          {audioDeviceOptions}
        </select>
      </div>
    </div>
  )
}

Settings.displayName = 'Video Settings'
