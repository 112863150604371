import { useEffect } from 'react'
import { BLOCK_MODE } from '../live-stream'

export default function ({ liveStreamElementRef, blockMode, setBlockMode }) {
  useEffect(() => {
    const el = liveStreamElementRef.current
    const isFullScreenSupported = !!el.requestFullscreen

    const exitFullScreen = () => {
      if (isFullScreenSupported) {
        if (document.fullscreenElement) document.exitFullscreen()
      } else {
        el.classList.remove('live--fullscreen-mode-mobile')
        document.body.classList.remove('disable-scroll')
      }
    }
    const enterFullScreen = () => {
      if (isFullScreenSupported) {
        if (!document.fullscreenElement) el.requestFullscreen()
      } else {
        el.classList.add('live--fullscreen-mode-mobile')
        document.body.classList.add('disable-scroll')
      }
    }

    el.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        setBlockMode(BLOCK_MODE.block)
      }
    }

    if (blockMode === BLOCK_MODE.fullscreen) {
      enterFullScreen()
    } else {
      exitFullScreen()
    }
  }, [blockMode, liveStreamElementRef, setBlockMode])
}
