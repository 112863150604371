import React, { useEffect, useRef, useState } from 'react'
import _throttle from 'lodash/throttle'
import Form from './form'
import useMessage from '../hooks/use-messages'
import Item from './item'

import { scrollTo } from '../../../utils'

export default function Body({ baseObjects, messages, setMessages, chatOpen, errorHandler }) {
  const { liveStream, ticket, event, csrf } = baseObjects
  const itemsRef = useRef(null)
  const [inBottom, setInBottom] = useState(false)
  const { loading } = useMessage({ setMessages, ticket, liveStream, csrf })

  const scrollToBottom = (animate = false) => {
    const itemsEl = itemsRef.current
    if (animate) {
      scrollTo(itemsEl, itemsEl.scrollHeight)
    } else {
      itemsEl.scrollTop = itemsEl.scrollHeight
    }
  }

  const items = messages.map((mess) => {
    const { id, from, body, isAdmin, deletedAt, createdAt } = mess
    const self = from === ticket.name
    return (
      <Item
        key={id}
        from={from}
        body={body}
        isAdmin={isAdmin}
        deletedAt={deletedAt}
        self={self}
        createdAt={createdAt}
      />
    )
  })

  useEffect(() => {
    if (inBottom) {
      scrollToBottom(true)
    }
  }, [inBottom, messages])

  useEffect(() => {
    if (!loading) scrollToBottom()
  }, [loading])

  useEffect(() => {
    const el = itemsRef.current
    const scrollListener = _throttle(() => {
      setInBottom(10 > Math.abs(el.scrollTop + el.offsetHeight - el.scrollHeight))
    }, 200)
    el.addEventListener('scroll', scrollListener)
    return () => {
      el.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return (
    <>
      <div ref={itemsRef} className="live-chat__items">
        {loading ? <div className="live-chat__loading" /> : items}
      </div>
      <Form
        ticket={ticket}
        event={event}
        liveStream={liveStream}
        chatOpen={chatOpen}
        csrf={csrf}
        scrollToBottom={scrollToBottom}
        errorHandler={errorHandler}
      />
    </>
  )
}
