import React, { useEffect, useRef } from 'react'
import Header from './chat/header'
import Body from './chat/body'
import BodyNeedSignup from './chat/body-need-signup'

export default function Chat({ showChat, setShowChat, messages, setMessages, chatOpen, errorHandler, baseObjects }) {
  const { ticket, event } = baseObjects
  const chatRef = useRef(null)

  useEffect(() => {
    const chatEl = chatRef.current
    if (showChat) {
      chatEl.classList.remove('live__chat--hidden')
    } else {
      chatEl.classList.add('live__chat--hidden')
    }
  }, [showChat])

  return (
    <div ref={chatRef} className="live__chat">
      <Header baseObjects={baseObjects} setShowChat={setShowChat} />
      {ticket ? (
        <Body
          baseObjects={baseObjects}
          messages={messages}
          setMessages={setMessages}
          chatOpen={chatOpen}
          errorHandler={errorHandler}
        />
      ) : (
        <BodyNeedSignup event={event} />
      )}
    </div>
  )
}
Chat.displayName = 'Chat'
