import { useEffect, useState } from 'react'
import useFetch from './use-fetch'

export default function ({ setMessages, ticket, liveStream, csrf }) {
  const [loading, setLoading] = useState(true)

  const res = useFetch('/common/api/live-stream-messages', {
    query: {
      csrf: csrf,
      ticketId: ticket.id,
      ticketToken: ticket.token,
      liveStreamId: liveStream.id,
    },
  })

  useEffect(() => {
    if (!res) return

    const newMessages = res.liveStreamMessages
    setLoading(false)
    setMessages(newMessages)
  }, [res, setMessages])

  return { loading }
}
