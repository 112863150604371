import React, { useState, useEffect } from 'react'
import Message from './video/message'
import AudioActivationOverlay from './video/audio-activation-overlay'
import Menu from './video/menu'
import Presenter from './video/presenter'
import Publisher from './video/publisher'
import Settings from './video/settings'
import { STATUS as LIVE_STATUS } from './live-stream'

export default function Video(props) {
  const {
    micOn,
    setMicOn,
    errorHandler,
    blockMode,
    setBlockMode,
    showChat,
    showViewers,
    chatDisabled,
    setShowChat,
    allowJoin,
    needTicketToWatch,
    publishRequest,
    createPublishRequest,
    abortPublishRequest,
    askToJoin,
    abortJoin,
    backgroundImage,
    backgroundMessage,
    baseObjects,
    sessionObjects,
    isTesting,
    setIsTesting,
    isLive,
    setIsLive,
  } = props
  const { liveStream, ticket, event } = baseObjects
  const { presenters, liveStreamStatus, subscribe, publish, unpublish, isConnected, numberOfViewers } = sessionObjects

  const [publisherIsLoading, setPublisherIsLoading] = useState(false)
  const [needAudioActivation, setNeedAudioActivation] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [screenShareOn, setScreenShareOn] = useState(false)
  const [cameraOn, setCameraOn] = useState(true)
  const [mutedLocal, setMutedLocal] = useState(false)
  const [audioDeviceId, setAudioDeviceId] = useState(undefined)
  const [videoDeviceId, setVideoDeviceId] = useState(undefined)
  const [cycleVideoDevice, setCycleVideoDevice] = useState(undefined)
  const [isPublishingCamera, setIsPublishingCamera] = useState(false)
  const [isPublishingScreen, setIsPublishingScreen] = useState(false)

  let publisher = null
  if (isTesting || isLive) {
    publisher = (
      <Publisher
        key="publisher"
        isLive={isLive}
        isTesting={isTesting}
        isConnected={isConnected}
        isPublishingCamera={isPublishingCamera}
        isPublishingScreen={isPublishingScreen}
        setIsPublishingCamera={setIsPublishingCamera}
        setIsPublishingScreen={setIsPublishingScreen}
        setPublisherIsLoading={setPublisherIsLoading}
        publish={publish}
        unpublish={unpublish}
        screenShareOn={screenShareOn}
        setScreenShareOn={setScreenShareOn}
        cameraOn={cameraOn}
        micOn={micOn}
        audioDeviceId={audioDeviceId}
        videoDeviceId={videoDeviceId}
        setVideoDeviceId={setVideoDeviceId}
        cycleVideoDevice={cycleVideoDevice}
        setCycleVideoDevice={setCycleVideoDevice}
        errorHandler={errorHandler}
      />
    )
  }

  useEffect(() => {
    if (!isConnected) {
      setIsLive(false)
      setIsTesting(false)
    }
  }, [isConnected, setIsLive, setIsTesting])

  const livePresenters = presenters.map((presenter) => {
    const { connection, muted, cameraOff, screenStream, cameraStream, videoStream } = presenter
    return (
      <Presenter
        key={connection.id}
        connection={connection}
        muted={muted}
        mutedLocal={mutedLocal}
        cameraOff={cameraOff}
        screenStream={screenStream}
        cameraStream={cameraStream}
        videoStream={videoStream}
        subscribe={subscribe}
        setNeedAudioActivation={setNeedAudioActivation}
      />
    )
  })
  const presentersCount = presenters.length + (isLive ? 1 : 0)
  const showPresenters = presentersCount + (isTesting ? 1 : 0) > 0
  const showMenu = !needTicketToWatch && isConnected && liveStreamStatus !== LIVE_STATUS.completed

  const menuProps = {
    actions: {
      createPublishRequest,
      abortPublishRequest,
      askToJoin,
      abortJoin,
    },
    values: {
      screenShareOn,
      cameraOn,
      micOn,
      mutedLocal,
      showSettings,
      blockMode,
      showChat,
      chatDisabled,
      allowJoin,
      publisherIsLoading,
      maxPresenters: presenters.length >= 4,
      showViewers: showViewers,
      numberOfViewers,
    },
    setters: {
      setIsTesting,
      setIsLive,
      setScreenShareOn,
      setCameraOn,
      setMicOn,
      setMutedLocal,
      setShowSettings,
      setBlockMode,
      setShowChat,
    },
  }

  return (
    <div className="live__video">
      {needAudioActivation && (
        <AudioActivationOverlay setNeedAudioActivation={setNeedAudioActivation} errorHandler={errorHandler} />
      )}
      {showMenu && (
        <Menu
          liveStream={liveStream}
          ticket={ticket}
          publishRequest={publishRequest}
          isLive={isLive}
          isTesting={isTesting}
          actions={menuProps.actions}
          values={menuProps.values}
          setters={menuProps.setters}
        />
      )}
      {showPresenters && (
        <div data-presenters={presentersCount} className="live-video__presenters">
          {[...livePresenters, publisher]}
        </div>
      )}
      {showSettings && (
        <Settings
          errorHandler={errorHandler}
          audioDeviceId={audioDeviceId}
          setAudioDeviceId={setAudioDeviceId}
          setCycleVideoDevice={setCycleVideoDevice}
          setShowSettings={setShowSettings}
        />
      )}
      <Message
        message={backgroundMessage}
        needTicketToWatch={needTicketToWatch}
        event={event}
        image={backgroundImage}
      />
    </div>
  )
}
Video.displayName = 'Video'
