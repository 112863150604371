import React, { useState, useRef, useEffect } from 'react'
import iconsFiles from '../utils/icons'

export default function AudioLevel({ muted, mutedLocal, audioLevel }) {
  const audioLevelRef = useRef(null)
  const [simpleAudioLevel, setSimpleAudioLevel] = useState(0)
  const [mutedLocalClass, setMutedLocalClass] = useState('')

  useEffect(() => {
    const audioLevelParsed = parseInt(audioLevel * 100)
    if (audioLevelParsed > 50) {
      setSimpleAudioLevel(3)
    } else if (audioLevelParsed > 20) {
      setSimpleAudioLevel(2)
    } else if (audioLevelParsed > 5) {
      setSimpleAudioLevel(1)
    } else {
      setSimpleAudioLevel(0)
    }
  }, [audioLevel])

  useEffect(() => {
    if (mutedLocal && !muted) {
      setMutedLocalClass('live-video__audio-level--muted-local')
    } else if (audioLevelRef.current) {
      setMutedLocalClass('')
    }
  }, [mutedLocal, muted])

  return (
    <>
      {!muted && (
        <div
          ref={audioLevelRef}
          className={`live-video__audio-level live-video__audio-level--${simpleAudioLevel} ${mutedLocalClass}`}
        ></div>
      )}
      {muted && <div className="live-video__muted" dangerouslySetInnerHTML={{ __html: iconsFiles.micDisabled }}></div>}
    </>
  )
}
AudioLevel.displayName = 'AudioLevel'
