import React, { useState, useEffect, useRef } from 'react'
import AudioLevel from './audio-level'

const subscriptionOptions = {
  width: '100%',
  height: '100%',
  showControls: false,
  insertMode: 'append',
}

export default function Presenter(props) {
  const {
    connection,
    muted,
    mutedLocal,
    cameraOff,
    screenStream,
    cameraStream,
    videoStream,
    subscribe,
    setNeedAudioActivation,
  } = props
  const screenRef = useRef(null)
  const cameraRef = useRef(null)
  const videoRef = useRef(null)
  const [audioLevel, setAudioLevel] = useState(null)
  const [cameraSubscriber, setCameraSubscriber] = useState(null)
  const [screenSubscriber, setScreenSubscriber] = useState(null)
  const [videoSubscriber, setVideoSubscriber] = useState(null)

  useEffect(() => {
    if (!cameraStream) return
    const subscriber = subscribe(
      cameraStream,
      cameraRef.current,
      Object.assign({}, subscriptionOptions, { fitMode: cameraStream.defaultFitMode })
    )

    subscriber.on('audioLevelUpdated', ({ audioLevel }) => {
      setAudioLevel(audioLevel)
    })

    subscriber.on('audioBlocked', () => {
      setNeedAudioActivation(true)
    })

    subscriber.on('audioUnblocked', () => {})

    if (subscriber.isAudioBlocked()) {
      setNeedAudioActivation(true)
    }

    setCameraSubscriber(subscriber)
    return () => {
      subscriber.off()
      setCameraSubscriber(null)
    }
  }, [cameraStream, setNeedAudioActivation, subscribe])

  useEffect(() => {
    if (!screenStream) return
    const subscriber = subscribe(
      screenStream,
      screenRef.current,
      Object.assign({}, subscriptionOptions, { fitMode: screenStream.defaultFitMode })
    )
    setScreenSubscriber(subscriber)
    return () => {
      subscriber.off()
      setScreenSubscriber(null)
    }
  }, [screenStream, subscribe])

  useEffect(() => {
    if (!videoStream) return
    const subscriber = subscribe(
      videoStream,
      videoRef.current,
      Object.assign({}, subscriptionOptions, { fitMode: 'contain' })
    )

    subscriber.on('audioBlocked', () => {
      setNeedAudioActivation(true)
    })

    subscriber.on('audioUnblocked', () => {})

    if (subscriber.isAudioBlocked()) {
      setNeedAudioActivation(true)
    }

    setVideoSubscriber(subscriber)
    return () => {
      subscriber.off()
      setVideoSubscriber(null)
    }
  }, [videoStream, setNeedAudioActivation, subscribe])

  useEffect(() => {
    if (!cameraSubscriber) return
    if (mutedLocal) {
      cameraSubscriber.setAudioVolume(0)
      if (videoSubscriber) videoSubscriber.setAudioVolume(0)
    } else {
      cameraSubscriber.setAudioVolume(100)
      if (videoSubscriber) videoSubscriber.setAudioVolume(100)
    }
  }, [cameraSubscriber, videoSubscriber, mutedLocal])

  useEffect(() => {
    if (!cameraRef.current) return
    if (cameraSubscriber && (screenSubscriber || videoSubscriber) && cameraOff) {
      cameraRef.current.style.opacity = 0
    } else {
      cameraRef.current.style.opacity = 1
    }
  }, [cameraOff, cameraSubscriber, screenSubscriber, videoSubscriber])

  const { host, ticketName } = JSON.parse(connection.data)
  const name = host ? 'Host' : ticketName
  const hideAudioLevel = muted && videoSubscriber

  return (
    <div
      className={`live-video__presenter ${
        screenSubscriber || videoSubscriber ? ' live-video__presenter--share-screen' : ''
      }`}
    >
      <div ref={screenRef} className="live-video__screen"></div>
      <div ref={cameraRef} className="live-video__camera"></div>
      <div ref={videoRef} className="live-video__video"></div>
      <div className="live-video__name">{name}</div>
      {!hideAudioLevel && <AudioLevel mutedLocal={mutedLocal} muted={muted} audioLevel={audioLevel} />}
    </div>
  )
}
Presenter.displayName = 'Presenter'
