import OT from '@opentok/client'
import { useState, useEffect, useCallback } from 'react'
import { STATUS } from '../live-stream'

export default function ({
  apiKey,
  liveStream,
  block,
  publishRequest,
  loadPublishRequest,
  setMessages,
  setMicOn,
  setShowChat,
  setChatDisabled,
  setAllowJoin,
  setBackgroundImage,
  setBackgroundMessage,
  setChatOpen,
  setShowViewers,
  setIsLive,
  setIsTesting,
  errorHandler,
}) {
  const [session, setSession] = useState(null)
  const [numberOfViewers, setNumberOfViewers] = useState(null)
  const [presenters, setPresenters] = useState([])
  const [liveStreamStatus, setLiveStreamStatus] = useState(liveStream.status)
  const [isConnected, setIsConnected] = useState(false)
  const [goLiveAfterReconnecting, setGoLiveAfterReconnecting] = useState(null)
  const isCompleted = liveStreamStatus === STATUS.completed

  const connect = useCallback(
    (token) => {
      if (!session || isCompleted) return
      session.connect(token || liveStream.token, () => {
        setIsConnected(true)
        if (goLiveAfterReconnecting) {
          setIsTesting(false)
          setIsLive(true)
          setGoLiveAfterReconnecting(false)
        }
      })
    },
    [liveStream.token, session, isCompleted, goLiveAfterReconnecting, setIsLive, setIsTesting]
  )

  useEffect(() => {
    if (session && !isConnected) connect(publishRequest?.token)
  }, [connect, isConnected, publishRequest?.token, session])

  useEffect(() => {
    if (isCompleted) return
    if (!liveStream.token) return
    if (session) return

    const sessionObject = OT.initSession(apiKey, liveStream.providerId)
    setSession(sessionObject)

    OT.on('exception', (error) => {
      errorHandler({
        message: 'An error occured, you may need to reload the page',
        description: `${error.message} (${error.code} ${error.name})`,
      })
      console.error('OT error', error) // eslint-disable-line
      throw error
    })
    sessionObject.on('exception', (error) => {
      errorHandler({
        message: 'An error occured, you may need to reload the page',
        description: `${error.message} (${error.code} ${error.name})`,
      })
      console.error('OT error', error) // eslint-disable-line
      throw error
    })
    sessionObject.on('signal:liveStreamStatus', (event) => {
      const { status } = JSON.parse(event.data)
      setLiveStreamStatus(status)
      if (status === STATUS.completed) {
        sessionObject.off()
        sessionObject.disconnect()
        setSession(null)
      }
    })
    sessionObject.on('signal:blockChange', (event) => {
      const data = JSON.parse(event.data)
      if (data.blockId !== block.id) document.location.reload()
    })
    sessionObject.on('sessionDisconnected', (event) => {
      if (event.reason === 'forceDisconnected') {
        errorHandler({ message: 'Disconnected', description: 'You have been disconnected by the host.' })
      }
      setIsConnected(false)
      loadPublishRequest()
    })
    sessionObject.on('streamPropertyChanged', (event) => {
      const { changedProperty, newValue, stream } = event
      if (changedProperty === 'hasAudio') {
        setPresenters((presenters) => {
          const presenter = presenters.find((p) => p.cameraStream.id === stream.id)
          if (presenter) presenter.muted = !newValue
          return [...presenters]
        })
      }
      if (changedProperty === 'hasVideo') {
        setPresenters((presenters) => {
          const presenter = presenters.find((p) => p.cameraStream.id === stream.id)
          if (presenter) presenter.cameraOff = !newValue
          return [...presenters]
        })
      }
    })
    sessionObject.on('signal:forceMute', (event) => {
      const { mute } = JSON.parse(event.data)
      setMicOn(!mute)
    })
    sessionObject.on('signal:newBackgroundImage', (event) => {
      const { image } = JSON.parse(event.data)
      setBackgroundImage(image)
    })
    sessionObject.on('signal:newMessage', (event) => {
      const { message } = JSON.parse(event.data)
      setBackgroundMessage(message)
    })
    sessionObject.on('signal:chatOpen', (event) => {
      const { isOpen } = JSON.parse(event.data)
      setChatOpen(isOpen)
    })
    sessionObject.on('signal:showViewers', (event) => {
      const { showViewers } = JSON.parse(event.data)
      setShowViewers(showViewers)
    })

    sessionObject.on('streamCreated', ({ stream }) => {
      setPresenters((presenters) => {
        const { connection } = stream
        let presenter = presenters.find((p) => p.connection.id === connection.id)
        let isNew = false
        if (!presenter) {
          if (presenters.length >= 4) return
          isNew = true
          presenter = { connection }
        }
        if (stream.videoType === 'screen') {
          presenter.screenStream = stream
        } else if (stream.videoType === 'custom') {
          presenter.videoStream = stream
        } else {
          presenter.muted = !stream.hasAudio
          presenter.cameraOff = !stream.hasVideo
          presenter.cameraStream = stream
        }
        if (isNew) {
          return [...presenters, presenter]
        } else {
          return [...presenters]
        }
      })
    })
    sessionObject.on('streamDestroyed', ({ stream }) => {
      setPresenters((presenters) => {
        const { connection } = stream
        const presenter = presenters.find((p) => p.connection.id === connection.id)
        if (!presenter) return [...presenters]
        if (stream.videoType === 'screen') {
          presenter.screenStream = null
        } else if (stream.videoType === 'custom') {
          presenter.videoStream = null
        } else {
          presenter.cameraStream = null
        }
        if (presenter.screenStream == null && presenter.cameraStream == null && presenter.videoStream == null) {
          return [...presenters.filter((p) => !(p.connection.id === presenter.connection.id))]
        } else {
          return [...presenters]
        }
      })
    })
    sessionObject.on('signal:publishRequest', (event) => {
      if (event.from) return // Dont accept signals from other clients, only from the server

      const { token, status } = JSON.parse(event.data)
      if (status === 'join-declined') setIsTesting(false)

      loadPublishRequest({ token })
    })
    sessionObject.on('signal:chat', (event) => {
      if (event.from) return // Dont accept signals from other clients, only from the server
      try {
        const message = JSON.parse(event.data)
        setMessages((messages) => [...messages, message])
      } catch (e) {
        errorHandler(e)
      }
    })
    sessionObject.on('signal:chatSettingChange', (event) => {
      try {
        const { chatDisabled } = JSON.parse(event.data)
        if (chatDisabled) {
          setShowChat(false)
          setChatDisabled(true)
        } else {
          setChatDisabled(false)
        }
      } catch (e) {
        errorHandler(e)
      }
    })
    sessionObject.on('signal:deleteMessages', (event) => {
      if (event.from) return // Dont accept signals from other clients, only from the server
      const { messageIds } = JSON.parse(event.data)
      setMessages((messages) => [
        ...messages.map((m) => {
          if (messageIds.includes(m.id)) {
            m.deletedAt = new Date()
            m.body = ''
          }
          return m
        }),
      ])
    })
    sessionObject.on('signal:allowJoinSettingChange', (event) => {
      try {
        const { allowJoin } = JSON.parse(event.data)
        if (allowJoin) {
          setAllowJoin(true)
        } else {
          setAllowJoin(false)
        }
      } catch (e) {
        errorHandler(e)
      }
    })
    sessionObject.on('signal:viewers', (event) => {
      try {
        const { viewers } = JSON.parse(event.data)
        setNumberOfViewers(viewers)
      } catch (e) {
        errorHandler(e)
      }
    })
    sessionObject.on('signal:newSession', () => {
      window.location.reload()
    })
    return () => {
      sessionObject.off()
      sessionObject.disconnect()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!publishRequest) {
      setIsTesting(false)
      setIsLive(false)
      return
    }

    if (session && publishRequest.isApproved && publishRequest.token) {
      setGoLiveAfterReconnecting(true)
      session.disconnect()
    }
  }, [publishRequest, session, setIsLive, setIsTesting])

  const publish = useCallback(
    (publisher) => {
      return new Promise((resolve) => {
        if (!publisher) return
        session.publish(publisher, (error) => {
          if (error) {
            errorHandler(error)
          } else {
            resolve(publisher)
          }
        })
      })
    },
    [errorHandler, session]
  )
  const unpublish = useCallback(
    (publisher) => {
      if (!publisher) return
      return session.unpublish(publisher), [session]
    },
    [session]
  )
  const subscribe = useCallback(
    (stream, element, options) => {
      return session.subscribe(stream, element, options)
    },
    [session]
  )

  return { presenters, isConnected, liveStreamStatus, subscribe, publish, unpublish, numberOfViewers }
}
